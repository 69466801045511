<template>
  <div class="w-screen h-screen flex">
    <div class="flex flex-col mx-auto md:m-auto">
      <core-logo class="m-4"></core-logo>

      <div class="p-4 max-w-md">
        <h3 class="text-3xl mb-2 font-medium">
          Авторизация
        </h3>

        <p class="text-gray-500 mb-6 font-medium text-sm">
          Для доступа к системе необходимо авторизоваться
        </p>

        <form @submit="handleSubmit">
          <core-input
            class="mb-4"
            id="login"
            label="Логин"
            icon="mdi-account"
            placeholder="Введите логин"
            required
            v-model:modelValue="username"
          />
          <core-input
            id="password"
            label="Пароль"
            type="password"
            icon="mdi-lock"
            placeholder="Введите пароль"
            required
            v-model:modelValue="password"
          />

          <core-info
            class="my-4"
            type="danger"
            icon="mdi-close-circle"
            v-if="status.name === 'error'"
          >
            <p v-if="status.data === 4">
              Неверное имя пользователя или пароль!
            </p>
            <p v-else-if="status.data === 5">
              Сервер не отвечает. Попробуйте позже
            </p>
            <p v-else-if="status.data === -1">
              Вы отключены от сети. Проверьте работоспособность локальной сети
              на вашем ПК и попробуйте позже
            </p>
            <p v-else>Код ошибки {{ status.data }}</p>
            <ul class="text-xs mt-2 list-disc ml-4 text-red-500">
              <li>Проверьте правильность введенных данных</li>
              <li>Попробуйте перезагрузить страницу</li>
            </ul>
          </core-info>

          <div class="mt-4">
            <core-button
              class="w-full h-12 rounded-md justify-center relative"
              type="primary"
            >
              Войти

              <icon class="absolute right-4" icon="mdi-arrow-right" />
            </core-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import AuthService from "@/app/modules/healths/services/auth.service";
import UnitService from "@/app/modules/healths/services/unit.service";

import { useAuthStore } from "@/app/modules/healths/store/auth.store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const username = ref("");
    const password = ref("");

    const status = ref({
      name: "success",
      data: 0
    });

    const authStore = useAuthStore();
    const router = useRouter();

    const handleSubmit = async e => {
      e.preventDefault();

      if (!username.value.trim() || !password.value.trim()) return;

      try {
        status.value.name = "pending";

        const session = await AuthService.login(username.value, password.value);
        const unit = await UnitService.getUnit();

        authStore.setSession(session);
        authStore.setUnit(unit[0].identifier);

        sessionStorage.setItem("profile", JSON.stringify(session));
        sessionStorage.setItem("unit", unit[0].identifier);

        status.value.name = "success";

        await router.push({
          name: "unit",
          params: { unit_id: unit[0].identifier }
        });
      } catch ({ response }) {
        if (!response) {
          status.value.name = "error";
          status.value.data = -1;
          return;
        }

        status.value.name = "error";
        status.value.data =
          response.status && Math.floor(response.status / 100);
      }
    };

    return {
      username,
      password,
      status,

      handleSubmit
    };
  }
});
</script>

<style scoped>
.login {
  background: url("../shared/assets/images/bg.jpg") #fbcfe8;
  background-size: cover;
}

.f {
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%);
}
</style>
